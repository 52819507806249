import React from 'react'
import '../../styles/wallet.style.scss'
import { formatCurrency, USDvsVND } from '../../../../utils/format'
import { useAuth } from '../../../../contexts/Authenticate'
import {Button, Flex} from 'antd'
import {useModal} from "../../../../contexts/Modal";
import DepositAmountModal from "./modals/DepositAmountModal";

function WalletBlock() {
    const { user } = useAuth()
    const {openModal} = useModal()

    const openDepositAmountModal = () => openModal(<DepositAmountModal amount='11500000' />)

    return (
        <div className='wallet-card'>
            <p className='font-weight-bold'>{'Thông tin ví chính'}</p>
            <p className='wallet-card__amount'>{formatCurrency(user?.credit, { rate: USDvsVND, prefix: '$' })}</p>
            <Flex align='center' justify='space-between' className='mt-2'>
                <p>≈ {formatCurrency(user?.credit, { suffix: 'đ' })}</p>
                <Button type='primary' onClick={openDepositAmountModal}>Nạp tiền</Button>
            </Flex>
        </div>
    )
}

export default WalletBlock
