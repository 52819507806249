import Router from 'core/Routes'
import SideBar from 'core/SideBar'
import Header from 'core/Header'
import { Suspense } from 'react'
import { Layout, Spin } from 'antd'
import { Content, Footer } from 'antd/es/layout/layout'
import { useAuth } from 'contexts/Authenticate'

const Wrapper = () => {
    const {collapsed} = useAuth()
    return (
        <Layout hasSider>
            <SideBar />
            <Layout style={{ marginInlineStart: !collapsed ? 220 : 80 }}>
                <Header />
                <Suspense
                    fallback={
                        <div
                            style={{
                                position: 'absolute',
                                top: '50%',
                                right: '50%'
                            }}
                        >
                            <Spin size='large' />
                        </div>
                    }
                >
                    <Content className='p-3'>
                        <Router />
                    </Content>
                </Suspense>
            </Layout>
        </Layout>
    )

}
export default Wrapper
