import React from 'react'
import { Button, Col, Divider, Flex, Image, message, Modal, Row } from 'antd'
import { useModal } from '../../../../../contexts/Modal'
import '../../../styles/wallet.style.scss'
import { useAuth } from '../../../../../contexts/Authenticate'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { formatCurrency, USDvsVND } from '../../../../../utils/format'

const BANK = {
    name: 'ACB',
    logo: 'https://salt.tikicdn.com/ts/document/29/f7/36/6e0320744bb1b75f9f8987f5f3143a0d.png',
    qrLink: 'https://api.vietqr.io/image/970416-43168167-YuDxZqB.jpg',
    status: 'ACTIVE',
    type: 'bank',
    config: {
        fixedFee: 0,
        flexibleFee: 0
    },
    info: {
        cardHolder: 'NGUYEN HOP QUANG',
        cardNumber: '43168167'
    }
}

function PaidModal({ amount }: { amount: string }) {
    const { closeModal, openModal } = useModal()
    const { user } = useAuth()

    return (
        <Modal maskClosable={false} closable open onCancel={() => closeModal()} title='Thanh toán hóa đơn' footer={null} width={700} className='wallet-paid'>
            <Row gutter={2} className='mt-4'>
                <Col xs={24} lg={10}>
                    <div>
                        <Flex align='center' justify='center' vertical>
                            <p className='text-lg font-weight-bolder'>{'Quét mã QR'}</p>
                            <p className='text-second text-center px-4'>{'Mở ứng dụng ngân hàng quét mã QR và xác nhận'}</p>
                            <div className='border-corner-qr'>
                                <div className='border-corner-qr__inner'>
                                    <Image
                                        src={`${BANK.qrLink}?amount=${amount}&addInfo=${`TIKCRM${user?.identifier}`}`}
                                        style={{ width: 160, height: 160 }}
                                    />
                                </div>
                            </div>
                        </Flex>
                    </div>
                </Col>
                <Col xs={24} lg={14}>
                    <div>
                        <p className='text-center text-lg font-weight-bolder'>{'Chuyển khoản bằng thông tin'}</p>
                        <p className='text-second text-center'>{'Vui lòng chuyển khoản chính xác nội dung bên dưới'}</p>
                        <Flex gap={4} className='bank-block mt-5' vertical>
                            <Flex justify='space-between' align='center'>
                                <p className='text-second font-weight-bold'>{'Chủ tài khoản'}</p>
                                <p className='font-weight-bold'>{BANK.info.cardHolder}</p>
                            </Flex>
                            <Flex justify='space-between' align='center'>
                                <p className='text-second font-weight-bold'>{'Ngân hàng'}</p>
                                <p className='font-weight-bold'>{BANK.name}</p>
                            </Flex>
                            <Flex justify='space-between' align='center'>
                                <p className='text-second font-weight-bold'>{'Số tài khoản'}</p>
                                <CopyToClipboard text={BANK.info.cardNumber} onCopy={() => message.success(`Đã sao chép: ${BANK.info.cardNumber}`)}>
                                    <p className='clickable font-weight-bold'>{BANK.info.cardNumber}</p>
                                </CopyToClipboard>
                            </Flex>
                            <Flex justify='space-between' align='center'>
                                <p className='text-second font-weight-bold'>
                                    {'Nội dung'} <span className='text-danger'>*</span>
                                </p>
                                <CopyToClipboard
                                    text={`TIKCRM${user?.identifier}`}
                                    onCopy={() => message.success(`Đã sao chép: ${`TIKCRM${user?.identifier}`}`)}
                                >
                                    <p className='clickable font-weight-bold'>TIKCRM{user?.identifier}</p>
                                </CopyToClipboard>
                            </Flex>
                            <Flex justify='space-between' align='center'>
                                <p className='text-second font-weight-bold'>{'Phí giao dịch'}</p>
                                <p className='text-success font-weight-bold'>{'Miễn phí'}</p>
                            </Flex>
                        </Flex>
                    </div>
                </Col>
            </Row>
            <Flex justify='space-between' align='center' className='mt-5'>
                <p className='font-weight-bolder' style={{ fontSize: 20 }}>{'Tổng thanh toán'}</p>
                <Flex vertical align='flex-end' gap={2}>
                    <p className='font-weight-bolder' style={{ fontSize: 20 }}>{formatCurrency(amount, { prefix: '$', rate: USDvsVND })}</p>
                    <p className='text-second text-lg font-weight-bold'>~{formatCurrency(amount, { suffix: 'đ',  })}</p>
                </Flex>
            </Flex>
            <Divider />

            <div className='payment-notice'>
                <p className='text-center' style={{ color: '#405CFF' }}>{'Sau khi chuyển khoản vui lòng bấm “Tôi đã thanh toán“ hệ thống sẽ tiến hành kiểm tra và tự động cộng tiền cho bạn sau 3-5 phút. Nếu quá thời gian trên tiền chưa được cộng vui lòng liên hệ với bộ phận CSKH qua livechat để được hỗ trợ."'}</p>
            </div>

            <Button size='large' type='primary' className='w-100 mt-4' onClick={() => window.location.reload()}>{'Tôi đã thanh toán'}</Button>
        </Modal>
    )
}

export default PaidModal
