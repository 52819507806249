import { useEffect, useState, FC } from 'react'
import { Select, Button, Form, Input, Modal, Divider, Space, Row, Col } from 'antd'
import _ from 'lodash'
import { notify } from 'contexts/Notification'
import StaffAPI from 'api/StaffAPI'
import { IRole } from 'types/IRole'
import {useAuth} from "../../../contexts/Authenticate";

const { Option } = Select
type Props = {
    visible: boolean
    toggleModal: (val: boolean) => void
    refetch: () => void
    item?: any
}

const StaffModal: FC<Props> = ({ visible, toggleModal, refetch, item }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const [partnerStaffs, setPartnerStaffs] = useState<any[]>([])
    const [selectedPartnerStaff, setSelectedPartnerStaff] = useState<any | null>(null)
    const [staffInfo, setStaffInfo] = useState<any | null>(null)
    const [roles, setRoles] = useState<IRole[]>([])
    const { user } = useAuth()

    useEffect(() => {
        if (!visible) return
        if (item) {
            StaffAPI.getRoles()
                .then((res) => {
                    setRoles(res)
                    if (item.role[0]) {
                        form.setFieldsValue({ role_id: item.role[0]._id })
                    }
                })
                .catch((err) => {
                    notify('error', err.message)
                })
                .finally(() => setLoading(false))
            // Edit item
            setStaffInfo({
                email: item.email,
                name: item.first_name
            })
            form.setFieldsValue({ email: item.email, name: item.first_name })
        } else {
            setLoading(true)
            StaffAPI.getRoles()
                .then((res) => {
                    setRoles(res)
                    return StaffAPI.getPartnerStaffs()
                })
                .then((res) => {
                    setPartnerStaffs(res)
                })
                .catch((err) => {
                    notify('error', err.message)
                })
                .finally(() => setLoading(false))
        }
    }, [visible])
    const onClose = () => {
        toggleModal(false)
        form.resetFields()
        setSelectedPartnerStaff(null)
        setStaffInfo(null)
    }
    const onFinish = async (values: any) => {
        console.log('Success:', values)
        try {
            setLoading(true)
            if (!item) {
                if (user.is_kbt_user && (!staffInfo?.email || !staffInfo?.name || !values.password)) {
                    notify('error', 'Vui lòng lấy thông tin nhân viên đối tác trước khi tạo nhân viên!')
                    return
                } else if (!values.password || !values.role_id) {
                    notify('error', 'Vui lòng nhập đủ thông tin trước khi tạo nhân viên!')
                    return
                } else {
                    const payload = {
                        ...values,
                        email: staffInfo?.email ?? values.email,
                        name: staffInfo?.name ?? values.name,
                        id: selectedPartnerStaff
                    }
                    await StaffAPI.createStaff(payload)
                    notify('success', 'Thêm nhân viên thành công!')
                }
            } else {
                const diff: any = {
                    role_id: values.role_id
                };
                if (values.password) {
                    diff.password = values.password
                }
                await StaffAPI.editStaff(item._id, diff)
                notify('success', 'Cập nhật thông tin thành công!')
            }
            onClose()
            refetch()
        } catch (error: any) {
            notify('error', error.message)
        } finally {
            setLoading(false)
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo)
    }

    const onSearchDetailStaff = () => {
        if (selectedPartnerStaff) {
            setLoading(true)
            StaffAPI.getDetailStaff(selectedPartnerStaff)
                .then((res) => {
                    setStaffInfo(res)
                    console.log('res', res)
                    form.setFieldsValue({
                        email: res.email,
                        name: res.name
                    })
                })
                .catch((err) => {
                    notify('error', err.message)
                })
                .finally(() => setLoading(false))
        }
    }

    const renderPartnerStaff = () => {
        return _.map(partnerStaffs, (item: any) => {
            return (
                <Option value={item.id} key={item.id}>
                    {item.id} - {item.name}
                </Option>
            )
        })
    }

    const renderRoles = () => {
        return _.map(roles, (item: any) => {
            return (
                <Option value={item._id} key={item._id}>
                    {item.name}
                </Option>
            )
        })
    }

    return (
        <Modal
            maskClosable={false}
            closable
            open={visible}
            onCancel={onClose}
            title={item ? 'Chỉnh sửa nhân viên' : 'Thêm mới nhân viên'}
            footer={null}
            width={1280}
            centered
            // style={{ margin: '25px' }}
        >
            <Divider />
            <Form name='CreateStaff' layout='vertical' onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete='off' form={form}>
                {!item && user.is_kbt_user && (
                    <Form.Item label={<b>Nhân viên đối tác</b>}>
                        <Row gutter={[20, 20]}>
                            <Col span={20}>
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder='Chọn Nhân viên đối tác'
                                    size='large'
                                    onChange={(value) => setSelectedPartnerStaff(value)}
                                    value={selectedPartnerStaff}
                                >
                                    {renderPartnerStaff()}
                                </Select>
                            </Col>
                            <Col span={4}>
                                <Button type='primary' size='large' onClick={onSearchDetailStaff} loading={loading}>
                                    Lấy Thông Tin NV
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                )}

                {(staffInfo || !user.is_kbt_user) && (
                    <>
                        <Form.Item name='email' label={<b>Email</b>}>
                            <Input placeholder='Email' readOnly={user.is_kbt_user || !!item} size='large' />
                        </Form.Item>
                        <Form.Item name='name' label={<b>Name</b>}>
                            <Input placeholder='Name' readOnly={user.is_kbt_user || !!item} size='large' />
                        </Form.Item>
                    </>
                )}
                <Form.Item label={<b>Phân quyền</b>} name='role_id' rules={[{ required: true, message: 'Vui lòng chọn vai trò cho nhân viên!' }]}>
                    <Select style={{ width: '100%' }} placeholder='Chọn vai trò cho nhân viên' size='large'>
                        {renderRoles()}
                    </Select>
                </Form.Item>
                {
                    item ?
                    <Form.Item label={<b>Mật khẩu</b>} name='password' extra={<span style={{color: 'red'}}>Nếu không reset password vui lòng bỏ qua</span>}>
                    <Input type='password' placeholder='Nhập mật khẩu' size='large' />
                </Form.Item> : <Form.Item label={<b>Mật khẩu</b>} name='password' rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}>
                    <Input type='password' placeholder='Nhập mật khẩu' size='large' />
                </Form.Item>
                }
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type='primary' htmlType='submit' loading={loading} size='large'>
                        {item ? 'Cập nhật' : 'Tạo nhân viên'}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default StaffModal
