import { useAuth } from 'contexts/Authenticate'
import _ from 'lodash'
import { filterConfigByPerms, treeRoutes } from 'core/Routes/RoutesConfig'
import {Flex, Layout, Menu} from 'antd'
import './SideBar.style.scss'
import type { MenuProps } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import UserPackage from "../../components/UserPackage";
type MenuItem = Required<MenuProps>['items'][number]

const getItem = (label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: 'group'): MenuItem => {
    if (type === 'group') {
        return {
            key,
            label,
            type,
            children: children || []
        }
    }
    return {
        key,
        icon,
        label,
        children
    }
}

const { Sider } = Layout

const SideBar = () => {
    const location = useLocation() // Lấy đường dẫn hiện tại
    const currentPath = location.pathname
    const { user, collapsed } = useAuth()
    const filteredRoutes = user.isOwner
        ? treeRoutes
        : filterConfigByPerms(treeRoutes, user?.permissions ? user?.permissions?.map((x: any) => x.code_permission) : [])

    const menuItems: MenuItem[] = filteredRoutes.map((route: any) => {
        if (route.isSidebarHeader) {
            return getItem(route.title, route.title, undefined, [], 'group')
        }
        return getItem(<Link to={route.path}>{route.title}</Link>, route.path, <i className={`fa fa-${route.icon}`} />)
    })

    return (
        <Sider width={220} trigger={null} collapsible collapsed={collapsed} className='sider'>
            <Flex vertical className='h-100'>
                <div className='demo-logo-vertical'>
                    <a className='sidebar-brand' href='/'>
                        <i className='align-middle' data-feather='box'/>
                        <span className='align-middle'>{!collapsed ? 'TikCRM' : 'Tik'}</span>
                    </a>
                </div>
                <Menu theme='dark' mode='inline' selectedKeys={[currentPath]} items={menuItems}
                      className='flex-grow-1'/>
                <UserPackage/>
            </Flex>
        </Sider>
    )
}

export default SideBar
