import { useEffect, useState, FC } from 'react'
import { Row, Select, Button, Form, Input, Modal, Divider, InputNumber } from 'antd'
import _ from 'lodash'
import { notify } from 'contexts/Notification'
import { UploadOutlined } from '@ant-design/icons'
import type { UploadFile } from 'antd'
import { message, Upload } from 'antd'
import Editor from 'core/Editor'
import DescriptionTemplateAPI from 'api/DescriptionTemplateAPI'
import { IDescriptionTemplate } from 'types/IDescriptionTemplate'

const { Option } = Select

type Props = {
    visible: boolean
    toggleModal: (val: boolean) => void
    refetch: () => void
    item?: IDescriptionTemplate
}

const DescriptionTemplateModal: FC<Props> = ({ visible, toggleModal, refetch, item }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const [type, setType] = useState('MANUAL') // MANUAL, IMPORT
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [jsonObject, setJsonObject] = useState([])
    const [description, setDescription] = useState('')

    useEffect(() => {
        if (!visible) return
        if (item) {
            form.setFieldsValue({
                name: item.name,
                position: item.position || 1
            })
            setDescription(item?.description)
        }
    }, [visible, item])
    const onClose = () => {
        form.resetFields()
        toggleModal(false)
        setFileList([])
        setJsonObject([])
        setType('MANUAL')
        setDescription('')
    }
    const onFinish = async (values: any) => {
        console.log('Success:', values)
        try {
            if (!description) {
                notify('error', 'Vui lòng nhập mô tả sản phẩm!')
                return
            }
            if (!item) {
                setLoading(true)
                let payload = {
                    ...values,
                    description
                }
                console.log('payload', payload)
                await DescriptionTemplateAPI.createDescriptionTemplate(payload)
                notify('success', 'Tạo mẫu thành công')
            } else {
                setLoading(true)
                let payload = {
                    ...values,
                    description
                }
                await DescriptionTemplateAPI.editDescriptionTemplate(item._id, payload)
                notify('success', 'Lưu mẫu thành công')
            }
            onClose()
            refetch()
        } catch (error: any) {
            notify('error', error.message)
        } finally {
            setLoading(false)
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo)
    }

    const handleFileChange = (info: any) => {
        try {
            setFileList([info.fileList[info.fileList.length - 1]])
            const reader = new FileReader()
            reader.onload = () => {
                const content = reader.result
                setJsonObject(JSON.parse(content as string))
            }
            reader.readAsText(info.fileList[info.fileList.length - 1].originFileObj)
        } catch (error) {
            message.error('Error reading file:')
        }
    }
    const handleImport = () => {
        if (!jsonObject) {
            message.error('Vui lòng chọn file cấu hình')
            return
        }
        if (!Array.isArray(jsonObject)) {
            message.error('File cấu hình không đúng định dạng')
            return
        }
        if (jsonObject.length === 0) {
            message.error('File cấu hình không có dữ liệu')
            return
        }
        setLoading(true)
        DescriptionTemplateAPI.importDescriptionTemplate({ configs: jsonObject as unknown as any[] })
            .then(() => {
                notify('success', 'Import thành công')
                onClose()
                refetch()
            })
            .catch((err: any) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }

    const onChangeDescription = (content: any, editor: any) => {
        if (editor?.getData()) {
            console.log('editor.getData()', editor.getData())
            setDescription(editor.getData())
        }
    }

    return (
        <Modal
            maskClosable={false}
            closable
            open={visible}
            onCancel={onClose}
            title='Cấu hình sản phẩm mẫu'
            footer={null}
            width={1280}
            centered
            // style={{ margin: '25px' }}
        >
            <Divider />
            <Select placeholder='Chọn' value={type} size='large' onChange={(val) => setType(val)} style={{ width: '100%' }} className='mb-3'>
                <Option value='MANUAL'>THỦ CÔNG</Option>
                <Option value='IMPORT'>IMPORT FILE</Option>
            </Select>
            {type === 'MANUAL' ? (
                <Form
                    name='DescriptionTemplateModal'
                    layout='vertical'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete='off'
                    form={form}
                >
                    <Form.Item label={<b>Name</b>} name='name' rules={[{ required: true, message: 'Vui lòng nhập tên mẫu!' }]}>
                        <Input placeholder='Nhập Tên mẫu' size='large' />
                    </Form.Item>
                    <p className='m-2'>
                        <b>Description</b>
                    </p>
                    <div className='mb-3'>
                        <Editor onChangeEditor={onChangeDescription} initialData={description} />
                    </div>
                    <Form.Item label={<b>Vị trí hiển thị</b>} name='position'>
                        <InputNumber placeholder='Nhập vị trí hiển thị' min={0} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type='primary' htmlType='submit' loading={loading} size='large'>
                            {item ? 'Lưu mẫu' : 'Tạo mẫu'}
                        </Button>
                    </Form.Item>
                </Form>
            ) : (
                <div className='mt-2'>
                    <Row gutter={[20, 20]} className='justify-content-center mb-3'>
                        <Upload
                            name='file'
                            beforeUpload={() => false}
                            multiple={false}
                            fileList={fileList}
                            onChange={handleFileChange}
                            accept='.json'
                            showUploadList={true}
                        >
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </Row>
                    <Row gutter={[20, 20]} className='justify-content-center mb-3'>
                        <Button type='primary' loading={loading} size='large' onClick={handleImport}>
                            Import mẫu
                        </Button>
                    </Row>
                </div>
            )}
        </Modal>
    )
}

export default DescriptionTemplateModal
