import React from 'react';
import {Helmet} from "react-helmet";
import {Card} from "antd";
import SettingFulfill from "../AccountManagement/SettingFulfill";

function FulfillManagement() {
    return (
        <Card title='Quản lý fulfill'>
            <Helmet>
                <title>Quản lý fulfill</title>
            </Helmet>
            <SettingFulfill />
        </Card>
    );
}

export default FulfillManagement;
