import React from 'react'
import { useTableConfigs } from '../../../../hooks/useTableConfigs'
import { useUserOrderQuery } from '../../queries/user-order.query'
import { USER_DEPOSIT_COLUMNS } from './Table/_userOrderColumnDefined'
import { Table } from 'antd'

function DepositTab() {
    const tableConfigs = useTableConfigs(useUserOrderQuery, { type: ['deposit'] })

    return (
        <Table
            bordered
            dataSource={tableConfigs.queryResponse?.data?.data ?? []}
            columns={USER_DEPOSIT_COLUMNS}
            loading={tableConfigs.queryResponse?.isFetching}
            pagination={{
                defaultCurrent: tableConfigs.pagination.pageNumber,
                current: tableConfigs.pagination.pageNumber,
                pageSize: tableConfigs.pagination.pageSize,
                total: tableConfigs.pagination.total,
                onChange: tableConfigs.pagination.handleChangePagination,
                position: ['topLeft', 'bottomRight'],
                showTotal: (total) => (
                    <span>
                        Tổng <strong>{total}</strong> đơn hàng
                    </span>
                )
            }}
            rowKey={(record) => record?._id}
        />
    )
}

export default DepositTab
