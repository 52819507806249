import React, { useState } from 'react'
import { useModal } from '../../../contexts/Modal'
import { Button, Col, Modal, Row, Upload } from 'antd'
import { IOrder, OrderItem } from '../../../types/IOrder'
import './artwork.style.scss'
import type { UploadFile, UploadProps } from 'antd'
import OrderAPI from '../../../api/OrderAPI'
import { notify } from '../../../contexts/Notification'
import {ArtworkModalData, ArtworkModalState} from "../interfaces/artwork.interface";
import {useMutation} from "@tanstack/react-query";

function ArtworkUploadModal({
    product,
    onUploadArtworkSuccess,
    artwork
}: {
    product: OrderItem
    artwork: Record<string, any>
    order: IOrder
    onUploadArtworkSuccess: (direction: string, url: string | null) => void
}) {
    const { closeModal } = useModal()
    const [uploadLoading, setUploadLoading] = useState<boolean>(false)
    const [removeDirection, setRemoveDirection] = useState<string[]>([])
    const [frontImage, setFrontImage] = useState<ArtworkModalState>({
        file: null,
        preview: artwork.front ?? null
    })
    const [backImage, setBackImage] = useState<ArtworkModalState>({
        file: null,
        preview: artwork.back ?? null
    })
    const [leftSleeveImage, setLeftSleeveImage] = useState<ArtworkModalState>({
        file: null,
        preview: artwork.left_sleeve ?? null
    })
    const [rightSleeveImage, setRightSleeveImage] = useState<ArtworkModalState>({
        file: null,
        preview: artwork.right_sleeve ?? null
    })

    const data: ArtworkModalData[] = [
        {
            image: frontImage,
            set: setFrontImage,
            direction: 'front',
            title: 'Front image'
        },
        {
            image: backImage,
            set: setBackImage,
            direction: 'back',
            title: 'Back image'
        },
        {
            image: leftSleeveImage,
            set: setLeftSleeveImage,
            direction: 'left_sleeve',
            title: 'Left sleeve image'
        },
        {
            image: rightSleeveImage,
            set: setRightSleeveImage,
            direction: 'right_sleeve',
            title: 'Right sleeve image'
        }
    ]

    const handleChange =
        (item: ArtworkModalData): UploadProps['onChange'] =>
        (info) => {
            item.set({
                file: info.fileList[0],
                preview: URL.createObjectURL(info.file as unknown as File)
            })

            setRemoveDirection(prev => prev.filter(dir => dir !== item.direction))
        }

    const uploadImage = async (image: ArtworkModalState, direction: string) => {
        if (!image.file || !image.file.originFileObj) {
            return {
                success: false,
                message: `Upload ${direction} error: Missing file upload data`
            }
        }
        try {
            const formData = new FormData()
            formData.append('file', image.file.originFileObj)
            formData.append('seller_sku', product.seller_sku)
            formData.append('item_id', product._id)
            formData.append('direction', direction)
            const response = await OrderAPI.uploadOrderItemArtwork(formData)
            return {
                success: true,
                data: response.data.preview
            }
        } catch (err) {
            return {
                success: false,
                message: `Upload ${direction} error: ${(err as Error).message}`
            }
        }
    }

    const uploadImages = async () => {
        setUploadLoading(true)
        const _data = data.filter((item) => !!item.image.file)

        for (let i = 0; i < _data.length; i++) {
            const response = await uploadImage(_data[i].image, _data[i].direction)
            if (response.success) {
                notify('success', `Upload ${_data[i].direction} successfully`)
                onUploadArtworkSuccess(_data[i].direction, response.data)
            } else {
                notify('error', response.message ?? '')
            }
        }

        if (removeDirection.length > 0) {
            try {
                await OrderAPI.removeOrderItemArtwork(product._id, removeDirection)
                notify('success', `Remove ${removeDirection.join(', ')} successfully`)
                removeDirection.forEach((direction) => {
                    onUploadArtworkSuccess(direction, null)
                })
            } catch (err) {
                notify('error', (err as Error).message)
            }
        }
        setUploadLoading(false)
        closeModal()
    }

    const removeImage = (item: ArtworkModalData) => () => {
        item.set({
            file: null,
            preview: null
        })
        setRemoveDirection((prev) => Array.from(new Set([...prev, item.direction])))
    }

    return (
        <Modal
            maskClosable={false}
            closable
            open
            onCancel={() => closeModal()}
            title={'Upload product artwork'}
            footer={null}
            width={800}
            className='artwork-config-modal'
        >
            <Row gutter={24}>
                {data.map((item) => (
                    <Col span={6} key={item.direction}>
                        <p>{item.title}</p>
                        <Upload
                            name={item.direction}
                            listType='picture-card'
                            className='avatar-uploader'
                            showUploadList={false}
                            accept='image/*'
                            onChange={handleChange(item)}
                            beforeUpload={() => false}
                        >
                            {item.image.preview ? (
                                <img src={item.image.preview} alt='avatar' style={{ width: '80%' }} />
                            ) : (
                                <button style={{ border: 0, background: 'none' }} type='button'>
                                    {/*{loading ? <LoadingOutlined/> : <PlusOutlined/>}*/}
                                    <div style={{ marginTop: 8 }}>Upload</div>
                                </button>
                            )}
                        </Upload>

                        {item.image.preview && (
                            <>
                                <OriginalButton product={product} item={item} />
                                <Button type='primary' danger className='w-100 mt-2' onClick={removeImage(item)}>
                                    Xóa ảnh
                                </Button>
                            </>
                        )}
                    </Col>
                ))}
            </Row>
            <Button type='primary' className='mt-4 w-100' onClick={uploadImages} loading={uploadLoading}>
                Xác nhận
            </Button>
        </Modal>
    )
}

export default ArtworkUploadModal

const OriginalButton = ({ product, item }: { product: OrderItem, item: ArtworkModalData }) => {

    const viewOriginalMutation = useMutation({
        mutationFn: OrderAPI.getSignedImageUrl,
        onSuccess: (data) => {
            if (data?.data) {
                window.open(data.data, '_blank')
            } else {
                notify('error', 'Cannot get original image url')
            }
        },
        onError: err => notify('error', (err as Error).message)
    })

    const originalUrl = product.artwork?.[item.direction as keyof typeof product.artwork]?.[1]

    const viewOriginal = async () => {
        if (!originalUrl) {
            return;
        }
        await viewOriginalMutation.mutateAsync(originalUrl.replace('https://', ''))
    }

    if (!item.image.preview) return null;

    return (
        <Button type='primary' className='w-100 mt-2' onClick={viewOriginal} disabled={!item.image.preview.startsWith('https')} loading={viewOriginalMutation.isLoading}>
            {(item.image.preview.startsWith('https') || originalUrl) ? 'Xem ảnh gốc' : 'Không khả dụng'}
        </Button>
    )
}
