import {IListApiResponse} from "../types/IApiResponse";
import ApiSender from "./ApiSender";
import {IOrder, IOrderQueryParams} from "../types/IOrder";
import {IFulfill, IUserFulfill} from "../types/IFulfill";

export default class OrderAPI {
    public static getOrders(params: IOrderQueryParams): Promise<IListApiResponse<IOrder>> {
        return ApiSender.get('/core/orders', params)
    }

    public static getOrderProductLabel(params: Record<string, any>) {
        return ApiSender.get('/core/orders/shipping-documents', params)
    }

    public static updateLineItem({ lineItemId, ...payload }: Record<string, any>) {
        return ApiSender.put(`/core/orders/update-line-item/${lineItemId}`, payload)
    }

    public static pushStatusToFulfillService(payload: Record<string, any>) {
        return ApiSender.post(`/core/fulfill/update-order-status`, payload)
    }

    public static getFulfillService(): Promise<IFulfill[]> {
        return ApiSender.get('/core/fulfill')
    }

    public static pushToFulfill(orderId: string) {
        return ApiSender.post(`/core/orders/${orderId}/push-to-fulfill`)
    }

    public static cancelFulfillOrder(orderId: string) {
        return ApiSender.put(`/core/orders/${orderId}/cancel-fulfill`)
    }

    public static getFulfillOrderDetail(orderId: string) {
        return ApiSender.post(`/core/fulfill/order-detail`, { orderId })
    }

    public static getUserFulfillServices(params?: Record<string, any>): Promise<IUserFulfill[]> {
        return ApiSender.get('/core/fulfill/user-services', params)
    }

    public static mutateUserFulfillService({ sId, data }: { sId?: string; data: Record<string, any> }) {
        if (!sId) {
            return ApiSender.post(`/core/fulfill/user-services`, data)
        }
        return ApiSender.put(`/core/fulfill/user-services/${sId}`, data)
    }

    public static uploadOrderItemArtwork(payload: any) {
        return ApiSender.post(`/core/orders/artwork/upload`, payload)
    }

    public static removeOrderItemArtwork(itemId: string, directions: string[]) {
        return ApiSender.post(`/core/orders/artwork/remove`, { directions, itemId })
    }

    public static getSignedImageUrl(url: string) {
        return ApiSender.post(`/core/orders/artwork/get-original`, { url })
    }
}
