import React from 'react'
import { useAuth } from '../contexts/Authenticate'
import { Flex } from 'antd'
import './user-package.scss'
// import PackageModal from "../pages/PaymentCenter/ui/Packages/modals/PackageModal";
import { useModal } from '../contexts/Modal'

function UserPackage() {
    const { user } = useAuth()
    const { openModal } = useModal()

    // const openUpgradePackageModal = () => openModal(<PackageModal />)

    return (
        <div className='user-package-box m-2'>
            <Flex gap={8} justify='space-between' align='center'>
                <p>{'Gói của bạn'}</p>
                <p className='text-warning text-lg font-weight-bold'>{user.package.package_detail?.name}</p>
            </Flex>

            <Flex gap={8} justify='space-between' align='center'>
                <p>Shop</p>
                <p className='text-warning text-lg font-weight-bold'>
                    {user?.package?.shop_used}/{user?.package?.shop_limit}
                </p>
            </Flex>
            {/*<Button className='extend-button mt-2' onClick={openUpgradePackageModal}>*/}
            {/*    Nâng cấp*/}
            {/*</Button>*/}
        </div>
    )
}

export default UserPackage
