import { Table, Row, Space, Button, Form, Popconfirm } from 'antd'
import { useEffect, useState, FC, useCallback } from 'react'
import { EditOutlined, ReloadOutlined, DeleteOutlined } from '@ant-design/icons'
import { blue, red } from '@ant-design/colors'
import moment from 'moment'
import _ from 'lodash'
import { notify } from 'contexts/Notification'
import StaffModal from './modals/StaffModal'
import { IUser } from 'types/IUser'
import StaffAPI from 'api/StaffAPI'
import { ColumnsType } from 'antd/lib/table'
import {useAuth} from "../../contexts/Authenticate";

const Staff = () => {
    const [staffs, setStaffs] = useState<IUser[]>([])
    const [loading, setLoading] = useState(false)
    const [pageSize, setPageSize] = useState(10)
    const [pageNumber, setPageNumber] = useState(1)
    const [total, setTotal] = useState(0)
    const [form] = Form.useForm()
    const [visibleModal, setVisibleModal] = useState(false)
    const [editedItem, setEditedItem] = useState<IUser | null>(null)
    const { user } = useAuth()

    const getStaffs = (query: { page_size: number; page_number: number }) => {
        setLoading(true)
        StaffAPI.getStaffs(query)
            .then((res) => {
                if (res.pagination && res.pagination.total >= 0) {
                    setTotal(res.pagination.total)
                }
                setStaffs(res.data)
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        refetchData()
    }, [])

    const refetchData = () => {
        getStaffs({
            page_number: pageNumber,
            page_size: pageSize
        })
    }

    const handleChangePagination = (_pageNumber: number, _pageSize: number) => {
        if (pageSize !== _pageSize) {
            setPageSize(_pageSize)
            getStaffs({
                page_number: pageNumber,
                page_size: _pageSize
            })
        } else if (pageNumber !== _pageNumber) {
            setPageNumber(_pageNumber)
            getStaffs({
                page_number: _pageNumber,
                page_size: pageSize
            })
        }
    }

    const toggleModal = useCallback(
        (value: boolean) => {
            setVisibleModal(value)
            if (!value) {
                setEditedItem(null)
            }
        },
        [visibleModal]
    )

    const onEdit = (item: IUser) => {
        setEditedItem(item)
        toggleModal(true)
    }

    const onRemove = (_id: string) => {
        setLoading(true)
        StaffAPI.removeStaff(_id)
            .then(() => {
                notify('success', 'Xóa nhân viên thành công')
                refetchData()
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }

    const columns: ColumnsType<IUser> = [
        ...(user.is_kbt_user ? ([
            {
                title: 'ID',
                dataIndex: 'partner_id',
                key: 'partner_id'
            },
        ] as ColumnsType<IUser>) : []),
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'First Name',
            dataIndex: 'first_name',
            key: 'first_name'
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            key: 'last_name'
        },
        {
            title: 'Vai trò',
            dataIndex: 'role',
            key: 'role',
            render: (text) => {
                if (text && text[0]) {
                    return text[0].name
                }
                return null
            }
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created_time',
            key: 'created_time',
            render: (text) => moment(text).format('DD/MM/YYYY HH:mm'),
            width: 150
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text, record) => {
                if (record._id !== record.owner) {
                    return (
                        <Space size='large'>
                            <EditOutlined
                                style={{ color: blue.primary, fontSize: '20px' }}
                                type='button'
                                onClick={() => onEdit(record)}
                                title='Chỉnh sửa nhân viên'
                            />
                            <Popconfirm title='Bạn chắc chắn xóa nhân viên này?' onConfirm={() => onRemove(record._id)}>
                                <DeleteOutlined style={{ color: red.primary, fontSize: '20px' }} title='Xóa nhân viên' />
                            </Popconfirm>
                        </Space>
                    )
                }
            },
            width: 150,
            fixed: 'right',
            align: 'center'
        }
    ]
    return (
        <>
            <Row justify='end' className='mb-3' gutter={[20, 20]}>
                <Space className='mr-2' size='large'>
                    <Button type='primary' onClick={refetchData} icon={<ReloadOutlined />}>
                        Refresh
                    </Button>
                    <Button type='primary' onClick={() => toggleModal(true)}>
                        Thêm nhân viên
                    </Button>
                </Space>
            </Row>
            <Form form={form} component={false}>
                <Table
                    bordered
                    dataSource={staffs}
                    columns={columns}
                    loading={loading}
                    pagination={{
                        defaultCurrent: pageNumber,
                        current: pageNumber,
                        pageSize,
                        total,
                        onChange: handleChangePagination,
                        position: ['topLeft', 'bottomRight'],
                        showTotal: (total) => (
                            <span>
                                Tổng số nhân viên: <strong>{total}</strong>
                            </span>
                        )
                    }}
                    rowKey={(record) => record?._id}
                    scroll={{
                        x: 500
                    }}
                />
            </Form>
            <StaffModal visible={visibleModal} toggleModal={toggleModal} refetch={refetchData} item={editedItem} />
        </>
    )
}

export default Staff
